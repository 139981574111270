import store from '@/store/store.js'

export const prices = {
    methods: {
        costSingle(discount) {
            if (store.getters.prices)
                return (
                    (store.getters.prices.single -
                        store.getters.prices.single * (discount / 100)) /
                    100
                )
            return 'NaN'
        },
        jointPrice(discount) {
            if (store.getters.prices)
                return (
                    (store.getters.prices.joint -
                        store.getters.prices.joint * (discount / 100)) /
                    100
                )
            return 'NaN'
        },
    },
}
