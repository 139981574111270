<template>
    <vs-table
        :onlyClickCheckbox="true"
        ref="table"
        v-model="selected"
        :sst="true"
        :data="logs"
    >
        <div slot="header" class="flex items-center flex-grow justify-end">
            <div>
                <vs-input icon="search" placeholder="Search" v-model="search" />
            </div>
        </div>

        <template slot="thead">
            <vs-th>Date Sent</vs-th>
            <vs-th>Client</vs-th>
            <vs-th>Code</vs-th>
            <vs-th>Status</vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr :data="log" :key="index" v-for="(log, index) in data">
                    <vs-td>
                        {{ formatDateAndTime(log.date_added) }}

                        <p v-if="log.status_description" class="text-warning">
                            {{ log.status_description }}
                        </p>
                    </vs-td>

                    <vs-td>
                        {{ log.client.name }}<br />
                        <p>
                            <small
                                >{{ log.client.email1 }}
                                <feather-icon
                                    v-if="log.client.email1_sent"
                                    class="ml-1"
                                    icon="CheckIcon"
                                    svgClasses="h-3 w-3 bg-success rounded-full text-white"
                                ></feather-icon>
                                <feather-icon
                                    v-if="log.client.email1_status"
                                    class="ml-1"
                                    icon="XIcon"
                                    svgClasses="h-3 w-3 bg-secondary rounded-full text-white"
                                ></feather-icon>
                                <span
                                    class="text-danger"
                                    v-if="log.client.email1_status"
                                    ><br />{{ log.client.email1_status }}</span
                                >
                            </small>
                        </p>
                        <p>
                            <small
                                >{{ log.client.email2 }}
                                <feather-icon
                                    v-if="log.client.email2_sent"
                                    class="ml-1"
                                    icon="CheckIcon"
                                    svgClasses="h-3 w-3 bg-success rounded-full text-white"
                                ></feather-icon>
                                <feather-icon
                                    v-if="log.client.email2_status"
                                    class="ml-1"
                                    icon="XIcon"
                                    svgClasses="h-3 w-3 bg-secondary rounded-full text-white"
                                ></feather-icon>
                                <span
                                    class="text-danger"
                                    v-if="log.client.email2_status"
                                    ><br />{{ log.client.email2_status }}</span
                                >
                            </small>
                        </p>
                        <p>
                            <small
                                >{{ log.client.phone1 }}
                                <feather-icon
                                    v-if="log.client.phone1_sent"
                                    class="ml-1"
                                    icon="CheckIcon"
                                    svgClasses="h-3 w-3 bg-success rounded-full text-white"
                                ></feather-icon>
                                <feather-icon
                                    v-if="log.client.phone1_status"
                                    class="ml-1"
                                    icon="XIcon"
                                    svgClasses="h-3 w-3 bg-secondary rounded-full text-white"
                                ></feather-icon>
                                <span
                                    class="text-danger"
                                    v-if="log.client.phone1_status"
                                    ><br />{{ log.client.phone1_status }}</span
                                >
                            </small>
                        </p>
                        <p>
                            <small
                                >{{ log.client.phone2 }}
                                <feather-icon
                                    v-if="log.client.phone2_sent"
                                    class="ml-1"
                                    icon="CheckIcon"
                                    svgClasses="h-3 w-3 bg-success rounded-full text-white"
                                ></feather-icon>
                                <feather-icon
                                    v-if="log.client.phone2_status"
                                    class="ml-1"
                                    icon="XIcon"
                                    svgClasses="h-3 w-3 bg-secondary rounded-full text-white"
                                ></feather-icon>
                                <span
                                    class="text-danger"
                                    v-if="log.client.phone2_status"
                                    ><br />{{ log.client.phone2_status }}</span
                                >
                            </small>
                        </p>
                    </vs-td>

                    <vs-td>
                        <div v-if="log.client && log.client.coupon">
                            <span>{{ log.client.coupon.code }}<br /></span>
                            <small
                                >{{
                                    log.client.coupon.price_discount_percentage
                                }}% Discount</small
                            >

                            <span v-if="log.client.coupon.quick_code_used"
                                ><br />Access Code:
                                {{
                                    log.client.coupon.quick_code_used.code
                                }}</span
                            >
                        </div>
                    </vs-td>

                    <vs-td>
                        <vx-tooltip
                            v-if="log.client.code_completed"
                            :text="formatDate(log.client.code_completed_date)"
                            title="Will Created"
                            color="primary"
                        >
                            <feather-icon icon="FileIcon"></feather-icon>
                        </vx-tooltip>
                        <vx-tooltip
                            v-else-if="log.client.code_used"
                            :text="formatDate(log.client.code_used_date)"
                            title="Account Created"
                            color="primary"
                        >
                            <feather-icon icon="UserIcon"></feather-icon>
                        </vx-tooltip>
                        <vx-tooltip
                            v-else-if="log.client.code_clicked"
                            :text="formatDate(log.client.code_clicked_date)"
                            title="Code Clicked"
                            color="primary"
                        >
                            <feather-icon
                                icon="MousePointerIcon"
                            ></feather-icon>
                        </vx-tooltip>
                        <vx-tooltip
                            v-else-if="log.client.code_sent"
                            :text="formatDate(log.client.code_sent_date)"
                            title="Invitation Sent"
                            color="primary"
                        >
                            <feather-icon icon="SendIcon"></feather-icon>
                        </vx-tooltip>
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'SendLogTable',
    mixins: [textFormat],
    props: ['data', 'itemsPerPage', 'view'],
    computed: {
        logs() {
            return this.data.data
        },
        search: {
            set(value) {
                if (value.length > 1 || value === '') {
                    this.searchQuery = value
                    this.$emit('search', value)
                }
            },
            get() {
                return this.searchQuery
            },
        },
    },
    data() {
        return {
            selected: [],
            searchQuery: '',
            status: 'All',
            selectMultiple: false,
        }
    },
    methods: {
        toggleSelect() {
            this.selected = []
            this.selectMultiple = !this.selectMultiple
        },
        setFilter(status, value) {
            this.status = status
            this.$emit('filter', value)
        },
        progressStatus(log) {
            if (log.client.code_completed) return 'Will Created'
            if (log.client.code_used) return 'Account Created'
            if (log.client.code_clicked) return 'Code Clicked'
            if (log.client.code_sent) return 'Code Sent'
        },
    },
}
</script>

<style>
.row-hidden-elements {
    visibility: hidden;
}
.vs-table tr:hover .row-hidden-elements {
    visibility: visible;
}
</style>
